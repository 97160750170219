<template>
    <div class="user-index">
        <div class="main-h1">Просмотр дела</div>
        <div class="card">
            <div class="card-title">{{ claim.title }}</div>
            <div class="card-body">
                <div class="card-body-title">Суммы</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Сумма иска</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount).toLocaleString()" disabled/>
                        </label>
                    </div>
                    <div class="col-3" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Арбитражный сбор</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount_sbor).toLocaleString()" disabled/>
                        </label>
                    </div>
                    <div class="col-3" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Гонорар арбитра</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount_arbiter).toLocaleString()" disabled/>
                        </label>
                    </div>
                    <div class="col-3" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Вознаграждение агента</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount_agent || 0).toLocaleString()" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Документы</div>
                <file-list :list="files.filter(item => item.type_id == 1)" @updated="updateFiles"/>
                <upload-file doc_type="1" parent_type="claim" :parent_id="claim.id" @uploaded="updateFiles"/>
            </div>
        </div>
    </div>
</template>

<script>
import uploadFile from "../../../components/file/upload";
import fileList from "../../../components/file/list";
export default {
    name: "edit",
    components:{uploadFile,fileList},
    data() {
        return {
            claim:{},
            files:[],
        }
    },
    methods: {
        updateFiles() {
            this.getFiles();
            this.logGet();
        },
        getClaim() {
            this.$api.get(`claim/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.logGet();
                    if (res.data.data.connected.length) {
                        res.data.data.connected.forEach(claimId => {this.getClaimConnected(claimId)})
                    }
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getFiles() {
            this.$api.get(`claim/${this.$route.params.id}/file`).then(res => {
                if (res.data.success) {
                    this.files = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        }
    },
    created() {
        this.getClaim();
        this.getFiles();
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) {
                this.getClaim();
                this.getFiles();
            }
        }
    }

}
</script>

<style scoped>

</style>